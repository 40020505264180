
body {
  min-height: 100%;  
  overflow-x: hidden;
}

.page-wrapper {
  margin: 0 auto;
  max-width: $maxwidth;
  position: relative;
}

.content-wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 100%;
}


.maincontent {
  margin-top: 5.5rem;
  @include clearfix;
  margin-left: auto;
  margin-right: auto;
  max-width: $maxwidth_header;
}

@include width(min, 50) {

  .maincontent {
    margin-top: 7.5rem;
  }

}

section,
footer {
  @include clearfix;
}

.container-inner {
  position: relative;
  max-width: $maxwidth_inner;
  margin: 0 auto;
  @include clearfix;
}

.container-margin {
  position: relative;
  @include margin-left(1);
  @include margin-right(1);
  @include cols(22);
}

.loader {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  pointer-events: none;
  background: #fff;
  opacity: 0;
  transition: opacity .5s;  
}

.loader:after {
	content: '';
	position: absolute;
	z-index: 10000;
	top: 50%;
	left: 50%;
	margin: -1.5rem 0 0 -1.5rem;
	width: 3rem;
	height: 3rem;

	border-left: 2px solid $farbe1;
	border-right: 2px solid transparent;
	border-top: 2px solid $farbe1;
	border-bottom: 2px solid $farbe1;
	border-radius: 50%;

	animation: rotateLoader 1.5s linear infinite;
  
}

@keyframes rotateLoader {
  100% { 
    transform: rotate(360deg); 
  }
}

.loading {

  .loader {
    pointer-events: auto;
    opacity: 1;
  }

}



