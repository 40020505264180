

// Layout 2

.textbild__layout-2 {

  &.textbild--c1 .textbild__element,
  &.textbild--c2 .textbild__element,
  &.textbild--c3 .textbild__element,
  &.textbild--c4 .textbild__element {
    @include cols(24);
  }

  &.textbild--c5 .textbild__element,
  &.textbild--c6 .textbild__element {
    @include cols(12);  
    
    &:nth-of-type(2n+1) {
      clear: both;
    }

  }


  @include width(min, 40) {
    &.textbild--c2 .textbild__element,
    &.textbild--c3 .textbild__element,
    &.textbild--c4 .textbild__element,
    &.textbild--c5 .textbild__element,
    &.textbild--c6 .textbild__element {

      @include cols(12);
      
      &:nth-of-type(2n+1) {
        clear: both;
      }

    }
  }

  @include width(min, 50) {
    &.textbild--c3 .textbild__element,
    &.textbild--c4 .textbild__element,
    &.textbild--c5 .textbild__element,
    &.textbild--c6 .textbild__element {
      @include cols(8);

      &:nth-of-type(2n+1) {
        clear: none;
      }

      &:nth-of-type(3n+1) {
        clear: both;
      }

    }
  }

  @include width(min, 60) {
    &.textbild--c5 .textbild__element,
    &.textbild--c6 .textbild__element {
      @include cols(6);

      &:nth-of-type(3n+1) {
        clear: none;
      }

      &:nth-of-type(4n+1) {
        clear: both;
      }

    }
  }

  @include width(min, 70) {
    &.textbild--c5 .textbild__element,
    &.textbild--c6 .textbild__element {
      @include cols(4.8);

      &:nth-of-type(4n+1) {
        clear: none;
      }

      &:nth-of-type(5n+1) {
        clear: both;
      }

    }
  }

  @include width(min, 90) {

    &.textbild--c6 .textbild__element {
      @include cols(4);

      &:nth-of-type(5n+1) {
        clear: none;
      }

      &:nth-of-type(6n+1) {
        clear: both;
      }

    }
  }


  @include width(min, 90) {

    &.textbild--c4 .textbild__element {

      @include cols(6);

      &:nth-of-type(3n+1) {
        clear: none;
      }

      &:nth-of-type(4n+1) {
        clear: both;
      }

    }
  }

}



// textbild__layout-2



.textbild__layout-2 {

  .textbild__headline {
    padding: 2rem 0;
    @include margin-left(1);
    @include margin-right(1);
    text-align: center;
  }

  .textbild__elements-container {
    border-top: 1px solid $grau2;
  }

  .textbild__element {
    float: left;
    border-bottom: 1px solid $grau2;
  }

}

@include width(min, 40) {

  .textbild__layout-2 {

    .textbild__headline {
      padding: 3rem 0;
    }

  }

}

@include width(min, 70) {

  .textbild__layout-2 {

    .textbild__headline {
      padding: 4rem 0;
    }
  
  }

}


// Vertical Padding 


.textbild__layout-2 {

  .textbild__element__inner {
    padding: 1rem 0;
  }
}

@include width(min,50) {

  .textbild__layout-2 {
    .textbild__element__inner {
      padding: 1.5rem 0;
    }
  }

}

@include width(min, 80) {

  .textbild__layout-2 {
    .textbild__element__inner {
      padding: 2rem 0;
    }
  }

}

@include width(min,90) {

  .textbild__layout-2 {
    .textbild__element__inner {
      padding: 2.5rem 0;
    }
  }

}




// Linien / Horizontal Padding


.textbild__layout-2 {

  .textbild__element__inner {
    @include margin-left(1);
    @include margin-right(1);
  }

  .textbild--c5.textbild__layout-2 .textbild__element,
  .textbild--c6.textbild__layout-2 .textbild__element {

    .textbild__element__inner {
      @include margin-left(2);
      @include margin-right(2);
    }

    &:nth-of-type(2n) {
      border-right: none;
    }

  }

}

@include width(min, 40) {

  .textbild__layout-2 {

    .textbild__element {
      border-right: 1px solid $grau2;
    }

    &.textbild--c2 .textbild__element,
    &.textbild--c3 .textbild__element,
    &.textbild--c4 .textbild__element,
    &.textbild--c5 .textbild__element,
    &.textbild--c6 .textbild__element {

      .textbild__element__inner {
        @include margin-left(2);
        @include margin-right(2);
      }

      &:nth-of-type(2n) {
        border-right: none;
      }
    }
  
  }

}

@include width(min, 50) {

  .textbild__layout-2 {
 
    &.textbild--c3 .textbild__element,
    &.textbild--c4 .textbild__element,
    &.textbild--c5 .textbild__element,
    &.textbild--c6 .textbild__element {

      .textbild__element__inner {
        @include margin-left(3);
        @include margin-right(3);
      }

      &:nth-of-type(2n) {
        border-right: 1px solid $grau2;
      }

      &:nth-of-type(3n) {
        border-right: none;
      }

    }
  }
}

@include width(min, 60) {

  .textbild__layout-2 {

    &.textbild--c5 .textbild__element,
    &.textbild--c6 .textbild__element {

      .textbild__element__inner {
        @include margin-left(4);
        @include margin-right(4);
      }

      &:nth-of-type(3n) {
        border-right: 1px solid $grau2;
      }

      &:nth-of-type(4n) {
        border-right: none;
      }

    }

  }
}

@include width(min, 70) {

  .textbild__layout-2 { 
  
    &.textbild--c5 .textbild__element,
    &.textbild--c6 .textbild__element {

      .textbild__element__inner {
        @include margin-left(5);
        @include margin-right(5);
      }

      &:nth-of-type(4n) {
        border-right: 1px solid $grau2;
      }

      &:nth-of-type(5n) {
        border-right: none;
      }

    }
  }
}

@include width(min, 90) {

  .textbild__layout-2 { 

    &.textbild--c4 .textbild__element {

      .textbild__element__inner {
        @include margin-left(4);
        @include margin-right(4);
      }

      &:nth-of-type(3n) {
        border-right: 1px solid $grau2;
      }

      &:nth-of-type(4n) {
        border-right: none;
      }

    }

    &.textbild--c6 .textbild__element {

      .textbild__element__inner {
        @include margin-left(6);
        @include margin-right(6);
      }

      &:nth-of-type(5n) {
        border-right: 1px solid $grau2;
      }

      &:nth-of-type(6n) {
        border-right: none;
      }

    }
  }
}

