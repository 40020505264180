// Logo

.logo {
  left: 0;
  top: 0;
  width: 8rem;
  margin-top: 1rem;
  @include margin-left(1);
}

// On Mouse-Hover and -Click (class always without period!)
@include events(logo) {
  opacity: .6;
  transition: opacity .3s;
}


@media only screen and (min-width: $navumschaltbreite) {

  .logo {
    width: 15rem;
  }

  .nav-container--not-hidden {
    &.nav-container--sticky {
      .logo {
        width: 8rem;
      }
    }
  }
}


// Farbe der Kopfleiste

.nav-container {
  background: #fff;

  &.nav-container--sticky {
    background: #efefef;
  }
}

// Höhe des Kopfes der Seite:

// 1. Abstand des Contents zum Kopf der Seite

.maincontent {
  margin-top: 6rem;
}
.nav-container {
  height: 6rem;
}


@include width(min, 50) {
  
  .maincontent {
    margin-top: 10.5rem;
  }
  .nav-container {
    height: 10.5rem;
  }

}

// Mobile-Menü Abstand der Navigationspounkte nach oben

@media only screen and (max-width: $navumschaltbreite) {

  .nav__ul {
    margin-top: 5em;
  }

  .nav-container--sticky .nav__ul {
    margin-top: 5rem;
  }
}
// Vertikale Positionierung der Navigationspunkte,
// wenn man am Kopf der Seite ist

@media only screen and (min-width: $navumschaltbreite) {

  .nav-container--not-hidden {
    .nav {
      padding-top: 2.5rem;
    }    
  }

}


// 2. Höhe des Navigationskastens, wenn man herunterscrollt

@media only screen and (min-width: $navumschaltbreite) {

  .nav-container--not-hidden {
  
    &.nav-container--sticky {
      height: 6rem;
      
      // Vertikale Positionierung der Navigationspunkte
      // wenn man am herunterscrollt      
      .nav {
        padding-top: .75rem;
      }
    }
    
  }

}

