


// Anmeldeformular

.body--kontakt--noscroll {
  overflow-y: hidden;
}



.kontakt-container {
	background: #fff;
	text-align: center;
}

.kontakt-container--overlay {
	opacity: 0;	
	position: fixed;
	z-index: 10000;
	left: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	overflow: scroll;	
	pointer-events: none;	
	transition: opacity .4s;	
}


.kontakt-container--open {
	opacity: 1;
	pointer-events: auto;	
}

.kontakt__error {
	margin: 1rem 0;
	text-align: center;
	width: 100%;
}

.kontakt__success {
	text-align: center;
	@include cols(22);
	@include margin-left(1);
	@include margin-right(1);
	display: none;	
	padding: 4rem 0;

	h1 {
		margin-bottom: 2rem;
	}
}

.kontakt-container--overlay .kontakt__success {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	padding: 0;
}

.kontakt__success--visible {
	display: block;
}

.kontakt__back {
	display: none;
	&.button--3 {
		display: none;
	}
}

.kontakt-container--overlay .kontakt__back {
	display: block;
	&.button--3 {
		display: inline-block;
	}
}



.kontakt__inner {
	position: relative;
	display: inline-block;
	margin-top: 4rem;
	margin-bottom: 10rem;	
  	@include margin-left(1);
  	@include margin-right(1);
  	@include cols(22);
  	max-width: 30rem;
}

@include width(min, 40) {
	.kontakt__inner {
	  @include cols(10);
	}
}

.kontakt__form {
	margin: 3rem 0 0;
}

.kontakt__form {

	input, 
	textarea {
	  transition: all .5s;
	  margin: 0 0 .5em;
	}

	input::-moz-focus-inner {
	  border: 0;
	  padding: 0;
	}

	input[type="text"], 
	input[type="email"], 
	textarea { 
	  -moz-appearance: none;
	  -webkit-appearance: none;
	  appearance: none;
	  width: 100%; 
	  border-radius: .25em;
	  border: 1px solid $grau2; 
	  padding: .5em .75em; 
	  display: block; 
	}

	input[type="text"]:focus, 
	input[type="email"]:focus, 
	textarea:focus {
	  box-shadow: none;
	  outline: none;
	  border-color: $farbe1;
	}

	textarea { 
	  width: 100%; 
	  min-width: 100%; 
	  min-height: 10rem;
	  max-width: 100%; 
	  resize: vertical;
	  overflow: auto;
	}

	.botcheck { 
	  display: none; 
	}

	input[type="checkbox"] {
	  cursor: pointer;
	}


	input[type="submit"] {
	  margin: 2rem 0 1rem;
	}

	a {
		position: relative;
		z-index: 1;
		display: inline-block;

		&:after {
			content: '';
			position: absolute;
			z-index: -1;
			top: 95%;
			width: 100%;
			display: block;
			height: 1px;
			background: $farbe1;
		}
	}

	/*  Input Placeholders  */

	::-webkit-input-placeholder { color: $grau3; opacity: 1; }
	input::-moz-placeholder { color: $grau3; opacity: 1; } 
	:-ms-input-placeholder { color: $grau3; opacity: 1; }
	input:-moz-placeholder { color: $grau3; opacity: 1; }

	:focus::-webkit-input-placeholder { color: $farbe1; opacity: 1; }
	input:focus::-moz-placeholder { color: $farbe1; opacity: 1; } 
	:focus:-ms-input-placeholder { color: $farbe1; opacity: 1; } 
	input:focus:-moz-placeholder { color: $farbe1; opacity: 1; }


	::-webkit-textarea-placeholder { color: $grau3; opacity: 1; }
	textarea::-moz-placeholder { color: $grau3; opacity: 1; }
	:-ms-textarea-placeholder { color: $grau3; opacity: 1; } 
	textarea:-moz-placeholder { color: $grau3; opacity: 1; }

	:focus::-webkit-textarea-placeholder { color: $farbe1; opacity: 1; }
	textarea:focus::-moz-placeholder { color: $farbe1; opacity: 1; } 
	:focus:-ms-textarea-placeholder { color: $farbe1; opacity: 1; } 
	textarea:focus:-moz-placeholder { color: $farbe1; opacity: 1; }

}

.no-touchevents .kontakt__inner a:hover,
.kontakt__inner a:active {
	color: $farbe1;
}


.kontakt__datenschutz__container {
	text-align: left;
	position: relative;
	margin: 2rem 0;

	input {
		position: absolute;
		top: .2em;
	}
	label {
		display: block;
		margin-left: 1.5em;
	}
}

.kontakt__pflicht {
	margin-top: 1rem;
	text-align: left;
}


.kontakt__backbutton { 
  position: absolute;
  z-index: 1500;
  @include right(1);
  top: 1rem;
  width: 2rem;
  height: 2rem;
  transition: all .4s; 
  border-radius: .25rem;
  cursor: pointer;
}

.no-touchevents .kontakt__backbutton:hover,
.kontakt__backbutton:active {
	box-shadow: 0 0 .5rem rgba(0,0,0,.25);
}

.kontakt__backbutton span, 
.kontakt__backbutton span:before {
  content: ''; 
  position: absolute; 
  top: 50%;
  height: 1px; 
  width: 100%; 
  display: block; 
  background:#000;
  transition: all .4s;
  transform-origin: center center;
}

.kontakt__backbutton span { 
  transform: rotate3d(0,0,1,45deg); 

  &:before {
    top:0; 
    bottom:0; 
    transform: rotate3d(0,0,1,90deg);
  }

}

