
.template--slideshow {
  position: relative;
  width: 100%;
  margin-bottom: 2rem;
  background: $farbe1;  
  overflow: hidden;  
}

.slideshow__slide {
  width: 100%;
  z-index: 10000;
  overflow: hidden;
}

.slideshow__slide__image {
  width: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity .4s;
  
  &.loaded {
    opacity: 1;
  }
}

.slideshow__slide__text {
  width: 100%;
}


// Dots

.flickity-page-dots {
  display: none;
}

.slideshow--pagedots .flickity-page-dots {
  display: block;
}



@include width(max, 50){

  .slideshow__slide__image {
    height: 0;
    padding-bottom: 60%;
    max-height: 50vh;
  }

  .slideshow__slide__text {
    width: 100%;
    padding: 1rem 0;
    color: #fff;
    background: $farbe1;
    text-align: center;    
  }

  .flickity-page-dots {
    position: static;
    margin: 1rem 0;
  }

}


@include width(min, 50) {

  .template--slideshow {
    height: 30rem;
  }

  .slideshow__slide {
    height: 100%;
  }

  .slideshow__slide__image {
    width: 100%;
    height: 30rem;
    background-size: cover;
    background-position: center;
  }

  .slideshow__slide__text {
    position: absolute;
    bottom: 10%;
    left: 0;
    padding: 2rem 0;
  }

  .flickity-page-dots {
    bottom: 1rem;
  }

}


// Slideshow Arrows

.slideshow__prev,
.slideshow__next {
  position: absolute;
  z-index: 100;
  top: 50%;
  height: 4rem;
  width: 1.25rem;
  margin-top: -2rem;
  cursor: pointer;
}

.slideshow__prev {
  left: .5rem;
}

.slideshow__next {
  right: .5rem;
}

.slideshow__prev,
.slideshow__next {
  &:active svg path {
    stroke: $farbe1;
  }
}

.no-touchevents {
  .slideshow__prev,
  .slideshow__next {
    &:hover svg path {
      stroke: $farbe1;
    }
  }
}


// Slideshow Typografie

.slideshow__slide__text  {

  h1 {
    margin-bottom: .75rem;
  }

}


// Weitere Informationen

.slideshow__weiter {
  margin: 1rem 0 0 -.5rem;
  display: inline-block;
  padding: .65rem .75rem .5rem;
  border-radius: .25rem;
  background: #fff;
  color: $farbe1;
  transition: all .3rem;
}

.no-touchevents .slideshow__weiter:hover,
.slideshow__weiter:active {
  background: $farbe1;
  color: #fff;
}

