
body {
	font-family: $schrift2;
	color: #111;
	font-weight: $schrift2-regular;
}

h1 {
	font-family: $schrift2;
	font-size: 1.8rem;
	line-height: 1.3;
}

h2 {
	font-family: $schrift2;
	font-size: 1.25rem;
	line-height: 1.3;
}


p,
li,
h3 {
	font-family: $schrift1;
}

input,
textarea,
label {
	font-family: $schrift2;
}

p,
li,
h3,
input,
textarea,
label {
	font-size: .9rem;
	line-height: 1.5;
}


p,
li {
	-webkit-hyphens: auto;
	hyphens: auto;
}

h3 {
	font-weight: $schrift1-bold;
}

strong {
	font-weight: $schrift1-bold;
}

em {
	font-style: italic;
}



// Listen

.textbild__element ul { 
	list-style: none inside; 
}

.textbild__element ul li { 
	padding:0 0 0 1em; 
	position: relative; 
}

.textbild__element ul li:before { 
	content:"•"; 
	position:absolute; 
	left:0; 
	color: $farbe1; 
}

.textbild__element ol { counter-reset:li; }

.textbild__element ol li { 
	padding:0 0 0 2em; 
	position: relative; 
}

.textbild__element ol li:before { 
  content: counter(li)"."; 
  counter-increment:li; 
  color: $farbe1; 
  position: absolute; 
  left: 0; 
  top: 0; 
}

.textbild__element__text a {
	position: relative;
	z-index: 1;
	display: inline-block;

	&:after {
		content: '';
		position: absolute;
		z-index: -1;
		top: 95%;
		width: 100%;
		display: block;
		height: 1px;
		background: $farbe1;
	}
}



.no-touchevents .textbild__headline a:hover,
.textbild__headline a:active {
	color: $farbe1;
}



@include width(min, 40) {

	h1 {
		font-size: 2.5rem;
	}

	h2 {
		font-size: 1.5rem;
	}

	p,
	input,
	textarea,
	label,
	li,
	h3 {
		font-size: 1.1rem;
	}

}


// Weitere

.text--marginal {
	font-size: .8rem;
	letter-spacing: .05rem;
}

.text--color-1 {
	color: $farbe1;
}

.text--color-2 {
	color: $farbe2;
}


$highlight-padding: .6rem;

@include width(min, 50) {

	.text--highlight-1 {
   		color: #fff;
		background-color: $farbe1;
		padding: $highlight-padding 0;
	    -webkit-box-decoration-break: clone;
	    -ms-box-decoration-break: clone;
	    -o-box-decoration-break: clone;
	    box-decoration-break: clone;
		box-shadow: $highlight-padding 0 0 $farbe1, -$highlight-padding 0 0 $farbe1;
	}

	.text--highlight-2 {
	    color: #fff;
		background-color: $farbe2;
		padding: $highlight-padding 0;
	    -webkit-box-decoration-break: clone;
	    -ms-box-decoration-break: clone;
	    -o-box-decoration-break: clone;
	    box-decoration-break: clone;
   		box-shadow: $highlight-padding 0 0 $farbe2, -$highlight-padding 0 0 $farbe2;
	}

}


.button--1,
.button--2,
.button--3, {
	font-family: $schrift2;
	display: inline-block; 
	padding: .6em .8em .5em; 
	margin: 0 0 2em;
	border: 1px solid $farbe1; 
	border-radius: .3em; 
	transition: all 0.3s;
	cursor: pointer; 
}

.no-touchevents .button--1:hover,
.button--1:active {
  color: $farbe1;
}

.button--2 {
	background: $farbe1; 
	color: #fff; 
}

.no-touchevents .button--2:hover,
.button--2:active {
	background: #fff;
	color: $farbe1;
}	

.button--3 {
	border: 1px solid $grau3; 
	background: $grau3; 
	color: #fff; 
}

.no-touchevents .button--3:hover,
.button--3:active {
	background: #fff;
	color: $grau3;
}	

