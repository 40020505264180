.loading {
  .logo, 
  .nav-container {
    pointer-events: none;
  }
}

.logo {
  position: relative;
  z-index: 1100;
  // left: 0;
  // top: 0;
  float: left;
  display: block;
  width: 5rem;
  margin-top: 1rem;
  @include margin-left(1);  
  opacity: 1;
  transition: all .3s;

  img {
    width: 100%;
  }
}

.no-touchevents .logo:hover,
.logo:active {
  opacity: .6;
  transition: opacity .3s;
}

@include width(min, 50) {

  .logo {
    width: 8rem;
  }

}


.nav-container {
  position: absolute;
  z-index: 1000;
  top: 0;
  width: 100%;
  max-width: $maxwidth;
}

.onepager:not(.startseite) {
  .nav,
  .nav__button {
    display: none;
  }
}

.nav {
  width: 100%;
  height: 2.5rem;
  padding: .75rem 0;
}

.nav-container--open .nav { 
  overflow-y: scroll; 
  -webkit-overflow-scrolling: touch; 
}

.body--noscroll {
  overflow-y: hidden;
}


.nav__li--active .nav__a {
  color: $farbe1;
} 

.no-touchevents .nav__li:hover .nav__a,
.nav__a:active {
  color: $farbe1;
}



// Subnav




// nav__button


.nav__button { 
  position: fixed;
  z-index: 1500;
  @include right(1);
  top: 1.4rem;
  width: 2.5rem;
  height: 2.5rem;
  transition: all .4s; 
  cursor: pointer;
}

.nav__button span, 
.nav__button span:before, 
.nav__button span:after {
  content: ''; 
  position: absolute; 
  height: 1px; 
  width: 100%; 
  display: block; 
  background:#000;
  transition: all .4s;
}

.nav__button span { 
  top: 50%; 

  &:before { 
    top:-7px;
  }
  &:after { 
    bottom:-7px;
  }

}

.no-touchevents .nav-container:not(.nav-container--open) .nav__button:hover span {
  
  &:before {
    transform: translate3d(0,-2px,0); 
  } 
  &:after { 
    transform: translate3d(0,2px,0); 
  }

}

.nav-container--open .nav__button span { 
  transform: rotate3d(0,0,1,45deg); 

  &:before,
  &:after {
    top:0; 
    bottom:0; 
    transform: rotate3d(0,0,1,90deg);
  }

}


// Mediaqueries

@media only screen and (max-width: $navumschaltbreite) {

  .nav {
    position: fixed;
    height: 100%;
    transition: transform .3s ease-in-out;
    transform: translate3d(0,-100%,0);   
    overflow: hidden;
    background: #fff;
  }

  .nav-container--open .nav {
    transform: translate3d(0,0,0);
    z-index: 1300;
  }

  .nav__ul {
    width: 100%;
    text-align: center;
    background: #fff;
  }
  
  .nav__li {
    border-bottom: 1px solid #eaeaea;
  }

  .nav__li:first-child {
    border-top: 1px solid #eaeaea;
  }

  .nav__a {
    font-size: 1.1rem;
    display: block;
    padding: 1rem;
  }

  .no-touchevents .nav__a:hover,
  .nav__a:active {
    background: #eaeaea;
  }


  .subnav__a {
    display: block;
    padding: .6rem .75rem .5rem;
    border-top: 1px solid #fff;
    background: #efefef;
    font-size: 1rem;
  }


  .subnav__li--active {
    pointer-events: none;
  }

  .subnav__li--active .subnav__a {
    color: $farbe1;
    pointer-events: none;
  } 

  .no-touchevents .subnav__a:hover, 
  .subnav__a:active {
    transition: all .3s;
    background: #eaeaea;
    color: $farbe1;
  }

  .nav-container--sticky {

    .nav__button { 
      width: 100%;
      top: 0;
      right: 0;
      background: #fff;      
      box-shadow: 0 0 .25rem rgba(0,0,0,.15);
    }

    .nav__button span {
      width: 3rem; 
      right: 1rem;
    }

  }

  .nav-container--sticky.nav-container--open {

    .nav__button { 
      @include right(1);
      top: 1.4rem;
      width: 2.5rem;
      height: 2.5rem;

      span {
        width: 100%;
        right: auto;
      }
    }

  }

} 

.no-touchevents .nav-container--open .nav__button:hover,
.nav-container--open .nav__button:active {
  background: $grau2;
}

@media only screen and (min-width: $navumschaltbreite) {

  // Not Hidden

  .no-touchevents .nav-container--not-hidden {
  
    transition: all .4s;

    &.nav-container--sticky {
      position: fixed;
      // background: #fff;
      // height: 4rem;
      box-shadow: 0 0 .5rem rgba(0,0,0,.25);

      .nav {
        padding-top: .75rem;
      }

      &.nav-container--not-hidden .nav__li {
        padding-bottom: .5rem;
      }

    }

    .nav {
      padding-top: 2.5rem;
      transition: all .4s;
    }

    .nav__button {
      opacity: 0;
      pointer-events: none;
    }

    .nav__ul {
      @include margin-right(1);
      float: right;
    }

    .nav__li {
      position: relative;
      float: left;
      margin-left:  2rem;
      padding-bottom: 1.5rem;
      // width: 8rem;
    }
      
    .nav__a {
      display: block;
      padding: .5rem 0;
    }


  // Subnav

    .subnav {
      position: absolute;
      top: 100%;
      left: 0;
      width: 200%;
      min-width: 20rem;
      opacity: 0;
    }

    .subnav:before {
      content: "";
      position: absolute;
      left: 1.5rem;
      top: -.625rem;
      width: 0; 
      height: 0; 
      border-left: .75rem solid transparent;
      border-right: .75rem solid transparent;
      border-bottom: .75rem solid $farbe1;
    }



    .subnav__inner {
      height: 0;
      overflow: hidden;    
      background: #fff;
      box-shadow: 0 .25rem .5rem rgba(0,0,0,.25);
      transition: height .3s;
      border-top: 2px solid $farbe1;
    }

    // Subnav align right
    .subnav--align-right {
      left: auto;
      right: 0;
    }

    .subnav--align-right:before {
      left: auto;
      right: 1.5rem;
    }

    .subnav__a {
      display: block;
      padding: .6rem .75rem .5rem;
      border-bottom: 1px solid #eaeaea;
    }

    .subnav__li:last-child .subnav__a {
      border-bottom: none;
    }


    .subnav__li--active {
      pointer-events: none;
    }

    .subnav__li--active .subnav__a {
      color: $farbe1;
      pointer-events: none;
    } 
  
  }

  .no-touchevents .nav-container--not-hidden .nav__li:hover .subnav {
    opacity: 1;
  }


  .no-touchevents .nav-container--not-hidden .subnav__a:hover, 
  .nav-container--not-hidden .subnav__a:active {
    transition: all .3s;
    background: #eaeaea;
  }


  // Hidden
  // (Use this also on Touchscreen)
  .touchevents .nav-container.nav-container--not-hidden,
  .nav-container--hidden {
    
    position: relative;

    .nav {
      position: fixed;
      top: 0;
      bottom: 0;
      height: 100%;
      left: auto;
      right: 0;
      width: 33.333%;
      padding: 5rem 2.5rem;
      background: #fff;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      transition: transform .4s;
      transform: translate3d(110%, 0, 0);
      box-shadow: 0 0 1rem rgba(0,0,0,.2);
    }

    .nav__blende {
      display: block;
      position: fixed;
      left: 0;
      width: 100%;
      height: 3rem;
      top: 0;
      background: #fff;
      opacity: 0;
    }

    &.nav-container--open .nav__blende,
    &.nav-container--sticky.nav-container--open .nav__blende {
      height: auto;
      bottom: 0;
      opacity: .5;
    }

    &.nav-container--sticky {
      
      .nav__blende {
        opacity: 1;
      }

      .nav__button {
        top: .25rem;
        background: #fff;
      }

    }


    .nav__li {
      border-bottom: 1px solid #eaeaea;
    }

    .nav__a {
      display: block;
      padding: .5rem 0;
    }

    .subnav__a {
      display: block;
      padding: 0 0 .5rem 1.5rem;
      position: relative;
    }
    
    .subnav__a:before {
      content:'→';
      position: absolute;
      left: -.1rem;
      color: $farbe1;
    }

    .subnav__li--active {
      pointer-events: none;
    }

    .subnav__li--active .subnav__a {
      color: $farbe1;
      pointer-events: none;
    } 

  }

  .no-touchevents .nav-container--hidden .subnav__a:hover {
    color: $farbe1;
  }

  .nav-container--open {
    .nav {
      transform: translate3d(0, 0, 0);
    }
  }

}
