// Browser Fenster kleiner als 50rem (800px)
// max = maximalbreite, min = minimalbreite

@include width(max, 50) {

  .slideshow__slide__image {
    height: 0;
    padding-bottom: 60%;
    max-height: 50vh;
  }

  .slideshow__slide__text {
    width: 100%;
    padding: 1rem;
    color: #fff;
    background: $farbe1;
    text-align: left; 
  }

  .flickity-page-dots {
    position: static;
    margin: 1rem 0;
  }

}


// Browser Fenster größer als 50rem (800px)

@include width(min, 50) {

  .template--slideshow {
    height: 30rem;
  }

  .slideshow__slide {
    height: 100%;
  }

  .slideshow__slide__image {
    width: 100%;
    height: 0;
    padding-bottom: 50%;
    height: 30rem;
    background-size: cover;
    background-position: center;
  }

  .slideshow__slide__text {
    position: absolute;
    bottom: 3%;
    left: 0;
    padding: 2rem 0;
  }

  .flickity-page-dots {
    bottom: 1rem;
  }

}

// Headerimage - Text Farbe 2

@include width(min, 50) {

  .headerimage__image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }


  .headerimage__text {
    position: absolute;
    bottom: 20%;
    left: 0;
  }
  
  .text--highlight-1 {
    color: $farbe2;
  }

}