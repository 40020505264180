
// Newsübersicht

.news-uebersicht {
	border-top: 1px solid $grau2;
	position: relative;
}

.news-uebersicht__kategorien {
	
	// margin-top: 2rem;
	padding: 1.2rem 1.5rem 1rem;
	overflow: hidden;
	background: #eaeaea;	
	text-align: center;

	h3 { 
		display: inline-block; 
		margin: 0 1rem 0 0;
	}
	
	ul { display: inline-block; }
}

.news-uebersicht__kategorien__li {

	position: relative;
	display: inline-block;
	margin: 0 1rem 0 0;
	cursor: pointer;

	&:before {
		content: '';
		position: relative;
		top: .1rem;
		display: inline-block;
		width: 1rem;
		height: 1rem;
		border: 2px solid #fff;
		margin: 0 .25rem 0 0;
		background: #fff;
		border-radius: .1rem;
		box-shadow: 0 0 .25rem $grau3;
		background: #fff;

	}

	// &:after {
	// 	content: '';
	// 	display: block;
	// 	position: absolute;
	// 	top: .2rem;
	// 	left: .1rem;
	// 	width: .8rem;
	// 	height: .8rem;
	// 	opacity: 0;
	// 	transform: scale(.1);
	// 	transition: all .3s;
	// }


	&.news-uebersicht__kategorien__li--checked:before {
		background: #fff url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBvbHlsaW5lIGZpbGw9IiNGRkZGRkYiIHN0cm9rZT0iIzFEMUQxQiIgc3Ryb2tlLXdpZHRoPSI0IiBwb2ludHM9IjEsMTIgNywyMiAyMiwxIi8+PC9zdmc+DQo=');
	}
}

.news__headline {
	padding: 2rem 0 1rem;
	text-align: center;
}

// @include min-40 {
// 	.news-uebersicht__kategorien__li:after {
// 		top: .4rem;
// 	}
// }

@include width(min, 70) {

    .news__headline {
      padding: 2rem 0;
    }

}


.news__masonry {
	width: 100%;
	position: relative;
	overflow: hidden;
}


// News-Artikel

.news-entry {
	border-top: 1px solid $grau2;

	.template--textbild:first-child {
		padding-top: 2rem;
	}
}


// News-Teaser


.news-teaser {
	transition: opacity .4s;
}

.news-teaser__date {
	font-size: .9rem;
	letter-spacing: .05rem;
	margin-bottom: .5rem;
}

.news-teaser__kategorien {
	margin-bottom: .5rem;
}

.news-teaser__kategorien li {
	font-size: .9rem;
	letter-spacing: .05rem;
	display: inline-block;

	&:after {
		content: "|";
		margin: 0 .5rem;
		color: #eaeaea;
	}

	&:last-child:after {
		content: "";
	}

}


.news-teaser h3 {
	font-size: 1.4rem;
	line-height: 1.3;
	margin-bottom: .7rem;
}

.no-touchevents .news-teaser a:hover,
.news-teaser a:active {
	color: $farbe1;
}

.news-teaser__weiter {
	display: inline-block;
	padding: .5rem .75rem .4rem;
	margin: 1.5rem 0 0;
	border: 1px solid $farbe1;
	border-radius: .25rem;

	a {
		display: block;
		font-size: .6rem;
		text-transform: uppercase;
		letter-spacing: .1rem;
	}
}







