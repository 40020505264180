.page-protector-container {
    max-width: 25rem;
    margin: 4rem auto;

    p, legend {
        font-family: $schrift1;
        display: block;
        width: 100%;
        margin-bottom: 1rem;
    }

    button {
        font-family: $schrift1;
        margin: 1rem 0 0;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 100%;
        padding: 0.5em 1em;
        background-color: $farbe1;
        color:#fff;
        text-decoration: none;
        border: 0 rgba(0,0,0,0);
        border-radius: 2px;
        cursor: pointer;
    }

    .no-touchevents button:hover,
    button:focus {
        background-color: $grau2;
    }
    button:focus {
        outline: 0;
    }

    input[type='text'],
    input[type='password'] {
        font-size: 100%;
        padding: 0.5rem;
        display: inline-block;
        border: 1px solid #ccc;
        box-shadow: inset 0 1px 3px #ddd;
        border-radius: 4px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

}

.button--logout.button--3 {
    margin: 2rem 0;
}

.logout-message {
    margin: 4rem 0;   
}
