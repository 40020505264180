// Schriften
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,600,300italic,600italic);
// @import url(https://fonts.googleapis.com/css?family=Merriweather:400,400italic,700);

$schrift1: 'Source Sans Pro', sans-serif;
$schrift2: 'Source Sans Pro', sans-serif;

// Als Wert: "normal" (ohne Anführungszeichen) oder eine Zahl
$schrift1-regular: 300;
$schrift2-regular: 300;

// Als Wert: "bold" (ohne Anführungszeichen) oder eine Zahl
$schrift1-bold: 600;
$schrift2-bold: 600;

// Farben

$farbe1: #8e392d; 
$farbe2: #ffe9c0;
$grau1: #efefef;
$grau2: #eaeaea;
$grau3: #ccc;

// Maximalbreite der Seite insgesamt
$maxwidth: 200rem;

// Maximalbreite der Header
$maxwidth_header: 80rem;

// Maximalbreite der inneren Seitenelemente
$maxwidth_inner: 80rem;

// Maximalbreite eines Text-Bild-Kastens
$textbox_maxwidth: 60rem;


// Breite, bei der auf Mobile-Navigation umgeschaltet wird
// 1rem = 16px
$navumschaltbreite: 66rem;

// Seitenhintergrund

body {
	// background-color: #f7ecd9;
	// background-image: url('http://localhost:8888/palliamo/root/hintergrund.jpg');	
}

.maincontent {
	background-color: #fff;
}

.footerinformationen.template--textbild {
  padding-top: 2rem;
  background: #f7ecd9; }

.embed-container {
  position: relative; 
  padding-bottom: 75.65%; /* 690 Breite : 1270 Höhe */
  height: 0; 
  overflow: hidden; 
  width: 100%;
  height: auto;
}
.embed-container iframe {

  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%; 
}