
.textbild__elements-container {
  @include clearfix;
  padding-bottom: 2rem;
}


.textbild__element__text {
	max-width: $textbox_maxwidth;
}

.textbild__element h2,
.textbild__element ul,
.textbild__element ol,
.textbild__element p {
  margin-bottom: 1rem;
}

.no-touchevents .textbild__element__text a:hover,
.textbild__element__text a:active {
	color: $farbe1;
}

.textbild__element__map {
	iframe {
		width: 100%;
	}
}

.textbild__map--above {
	margin: 0 0 1rem 0;
}

.textbild__map--under {
	margin: 1rem 0 0 0;
}

.textbild__headline {
	@include cols(22);
	@include margin-left(1);
	@include margin-right(1);
}