

// Text/Bild Images

.textbild__imagewrapper {
  width: 100%;
  height: 0;
  margin: 0 0 1rem;
  position: relative;

  img {
      opacity: 0;
      transition: opacity .5s;
      visibility: hidden;
      width: 100%;
  }

  &.loading {
    background: #eaeaea;
  }

  &.loading:before {
    content:'';
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: .75rem;
    border-top: 1px solid $farbe1;
    border-left: 1px solid $farbe1;
    border-bottom: 1px solid $farbe1;
    border-right: 1px solid transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -.75rem 0 0 -.75rem;
    animation: spinner 2s linear infinite;
  }

  &.inview {
    img {
      visibility: visible;
    }
  }

  &.loaded {
    img {
      opacity: 1;
    }
  }

}

@keyframes spinner {
  100% {
    transform: rotate(360deg); 
  }
}



.textbild__element__bild {
  margin: 0 0 1rem 0;
}

.textbild__imagecaption {
  font-weight: bold;
}



@include width(min, 40) {

  .textbild__image--right {
    width: 47.5%;
    float: right;

    &.textbild__element__bild {
      margin: 0 0 2rem 1.5rem;
    }

  }


}


// Bild als Link

a.textbild__element__bild {
  display: block;
  transition: opacity .3s;
}

.no-touchevents a.textbild__element__bild:hover,
a.textbild__element__bild:active {
  opacity: .8;
}




