
// Text-Bild-Element

.textbild__element h2,
.textbild__element p {
  margin-bottom: 1rem;
}

.textbild__element p {
  font-family: $schrift1;
  
  strong {
    font-weight: $schrift1-bold;
  }
}

.no-touchevents .textbild__element__text a:hover,
.textbild__element__text a:active {
  color: $farbe1;
}


.textbild__imagewrapper {
  margin: 0 0 1rem;

  &.loading {
    margin: 1rem 5% 0;
    width: 90%;
  }

  &.loading:before {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: .75rem;
    border-top: 1px solid $farbe1;
    border-left: 1px solid $farbe1;
    border-bottom: 1px solid $farbe1;
    border-right: 1px solid transparent;
    top: 50%;
    left: 50%;
    margin: -.75rem 0 0 -.75rem;
  }

}

.textbild__element__bild {
  margin: 0 0 1rem 0;
}

.textbild__imagecaption {
  font-weight: bold;
}



@include width(min, 40) {

  .textbild__image--right {
    width: 47.5%;

    &.textbild__element__bild {
      margin: 0 0 2rem 1.5rem;
    }

  }

}




.textbild__layout-1 {


  .textbild { 
    overflow: hidden;
    padding: 1rem 0;
  }

  .textbild__headline {
    padding: 2rem 0;
    text-align: center;
  }

  .textbild__element {
    float: left;
    padding: 1rem 0;
    @include margin-left(1);
  }


}


// textbild__layout-2



.textbild__layout-2 {

  .textbild__headline {
    padding: 2rem 0;
    @include margin-left(1);
    @include margin-right(1);
    text-align: center;
  }

  .textbild__elements-container {
    border-top: 1px solid $grau2;
  }

  .textbild__element {
    float: left;
    border-bottom: 1px solid $grau2;
  }


}

@include width(min, 40) {

  .textbild__layout-2 {

    .textbild__headline {
      padding: 3rem 0;
    }

  }

}

@include width(min, 70) {

  .textbild__layout-2 {

    .textbild__headline {
      padding: 4rem 0;
    }
  
  }

}


// Vertical Padding 


.textbild__layout-2 {

  .textbild__element__inner {
    padding: 1rem 0;
  }
}

@include width(min, 50) {

  .textbild__layout-2 {
    .textbild__element__inner {
      padding: 1.5rem 0;
    }
  }

}

@include width(min, 80) {

  .textbild__layout-2 {
    .textbild__element__inner {
      padding: 2rem 0;
    }
  }

}

@include width(min, 90) {

  .textbild__layout-2 {
    .textbild__element__inner {
      padding: 2.5rem 0;
    }
  }

}






// News


// Newsübersicht

.news-uebersicht {
  border-top: 1px solid $grau2;
}

.news__headline {
  padding: 2rem 0 1rem;
  text-align: center;
}

@include width(min, 70){

  .news__headline {
    padding: 2rem 0;
  }

}


// News-Artikel
.news-entry__date {

}




// News-Teaser


.news-teaser__date {
  font-size: .9rem;
  letter-spacing: .05rem;
  margin-bottom: .5rem;
}

.news-teaser h3 {
  font-size: 1.4rem;
  line-height: 1.3;
  margin-bottom: .7rem;
}

.no-touchevents .news-teaser a:hover,
.news-teaser a:active {
  color: $farbe1;
}

.news-teaser__weiter {
  display: inline-block;
  padding: .5rem .75rem .4rem;
  margin: 1.5rem 0 0;
  border: 1px solid $farbe1;
  border-radius: .25rem;

  a {
    display: block;
    font-size: .6rem;
    text-transform: uppercase;
    letter-spacing: .1rem;
  }
}





// news__layout-1

.news__layout-1 {

  overflow: hidden;
  padding: 1rem 0;

  .news-teaser {
    float: left;
    padding: 1rem 0;
    @include margin-left(1);
  }

}


// news__layout-2

.news__layout-2 {

  overflow: hidden;

  .news__headline {
    padding: 2rem;
    text-align: center;
    border-bottom: 1px solid $grau2;    
  }

  .news-teasers-container {
    border-top: 1px solid $grau2;
  }

  .news-teaser {
    float: left;
    border-bottom: 1px solid $grau2;
  }

  .news-teaser__inner {
    @include margin-left(1);
    @include margin-right(1);
    padding: 2rem 0;
  }

}
