

// Layout 2

.news__layout-2 {

  &.news--c2 .news-teaser,
  &.news--c3 .news-teaser,
  &.news--c4 .news-teaser,
  &.news--c2 .grid-sizer,
  &.news--c3 .grid-sizer,
  &.news--c4 .grid-sizer {
    @include cols(24);
  }

  @include width(min, 40) {

    &.news--c2 .news-teaser,
    &.news--c3 .news-teaser,
    &.news--c4 .news-teaser,
    &.news--c2 .grid-sizer,
    &.news--c3 .grid-sizer,
    &.news--c4 .grid-sizer {

      @include cols(12);
      
      &:nth-of-type(2n+1) {
        clear: both;
      }
    }

    &.news--c2 .news-teaser,
    &.news--c3 .news-teaser,
    &.news--c4 .news-teaser {
      &:nth-of-type(odd) {
        border-right: 1px solid $grau1;
      }
    }

  }

  @include width(min, 50) {

    &.news--c3 .news-teaser,
    &.news--c4 .news-teaser, 
    &.news--c3 .grid-sizer,
    &.news--c4 .grid-sizer {
      @include cols(8);

      &:nth-of-type(2n+1) {
        clear: none;
      }

      &:nth-of-type(3n+1) {
        clear: both;
      }

    }

    &.news--c3 .news-teaser,
    &.news--c4 .news-teaser {
      &:nth-of-type(odd) {
        border-right: none;
      }
      &:nth-of-type(3n+2) {
        border-right: 1px solid $grau1;
      }
      &:nth-of-type(3n+1) {
        border-right: 1px solid $grau1;
      }
    }


  }

  @include width(min, 90) {
    &.news--c4 .news-teaser,
    &.news--c4 .grid-sizer {
      @include cols(6);

      &:nth-of-type(3n+1) {
        clear: none;
      }

      &:nth-of-type(4n+1) {
        clear: both;
      }
    }

    &.news--c4 .news-teaser {
      &:nth-of-type(3n+2) {
        border-right: none;
      }
      &:nth-of-type(3n+1) {
        border-right: none;
      }
      &:nth-of-type(4n+1) {
        border-right: 1px solid $grau1;
      }
      &:nth-of-type(4n+3) {
        border-right: 1px solid $grau1;
      }
      &:nth-of-type(4n+2) {
        border-right: 1px solid $grau1;
      }
    }

  }

}


// news__layout-2



.news__layout-2 {

  overflow: hidden;

  .news__headline {
    padding: 2rem;
    text-align: center;
    border-bottom: 1px solid $grau2;    
  }

  .news-teasers-container {
    border-top: 1px solid $grau2;
  }

  .news-teaser {
    float: left;
    border-bottom: 1px solid $grau2;
  }

  .news-teaser__inner {
    @include margin-left(1);
    @include margin-right(1);
    padding: 2rem 0;
  }

  .textbild__imagewrapper {
    margin: 1rem 5% 0;
    width: 90%;
  }


  // .news__masonry:before, .news__masonry:after {
  //   display: block;
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   left: 50%;
  //   width: 1px;
  //   background: $grau2;
  // }


}




// Linien


@include width(min, 40) {

  .news__layout-2 {

    &.news--c2.news__layout-2 .news-teaser,
    &.news--c3.news__layout-2 .news-teaser,
    &.news--c4.news__layout-2 .news-teaser {

      .news-teaser__inner {
        @include margin-left(2);
        @include margin-right(2);
      }

    }
  
  }

}

@include width(min, 50) {

  .news__layout-2 {
 
    &.news--c3.news__layout-2 .news-teaser,
    &.news--c4.news__layout-2 .news-teaser {

      .news-teaser__inner {
        @include margin-left(3);
        @include margin-right(3);
      }

    }
  }
}

@include width(min, 90) {

  .news__layout-2 {

    &.news--c4.news__layout-2 .news-teaser {

      .news-teaser__inner {
        @include margin-left(4);
        @include margin-right(4);
      }

    }

  }
}

