
// textbild__layout-1



.textbild__layout-1 {


  .textbild { 
    overflow: hidden;
    padding: 1rem 0;
    // @include margin-left($seitenrand1);
    // @include margin-right($seitenrand1);
  }

  .textbild__headline {
    padding: 2rem 0;
    // @include margin-left($seitenrand1);
    // @include margin-right($seitenrand1);    
    text-align: center;
  }

  .textbild__element {
    float: left;
    padding: 1rem 0;
    @include margin-left(1);
    // @include margin-right($seitenrand1);    
  }


}


@include width(min, 70) {

  .textbild__layout-1 {

    .textbild__headline {
      padding: 2rem 0;
      @include margin-left(1);
      @include margin-right(1);      
    }

  }

}



.textbild__layout-1 {

  &.textbild--c1 .textbild__element,
  &.textbild--c2 .textbild__element,
  &.textbild--c3 .textbild__element,
  &.textbild--c4 .textbild__element {
    @include cols(22);
  }

  &.textbild--c5 .textbild__element,
  &.textbild--c6 .textbild__element {
    @include cols(10.5);
    
    &:nth-of-type(2n+1) {
      clear: both;
    }

  }

}

@include width(min, 40) {


  .textbild__layout-1 {

    &.textbild--c2 .textbild__element,
    &.textbild--c3 .textbild__element,
    &.textbild--c4 .textbild__element,
    &.textbild--c5 .textbild__element,
    &.textbild--c6 .textbild__element {

      @include cols(10.5);
      
      &:nth-of-type(2n+1) {
        clear: both;
      }

    }
  }
}

@include width(min, 50) {

  .textbild__layout-1 { 
    
    &.textbild--c3 .textbild__element,
    &.textbild--c4 .textbild__element,
    &.textbild--c5 .textbild__element,
    &.textbild--c6 .textbild__element {

      @include cols(6.66666666);

      &:nth-of-type(2n+1) {
        clear: none;
      }

      &:nth-of-type(3n+1) {
        clear: both;
      }

    }
  }
}

@include width(min, 60) {

  .textbild__layout-1 {

    &.textbild--c4 .textbild__element,
    &.textbild--c5 .textbild__element,
    &.textbild--c6 .textbild__element {

      @include cols(4.75);

      &:nth-of-type(3n+1) {
        clear: none;
      }

      &:nth-of-type(4n+1) {
        clear: both;
      }

    }
  }
}

@include width(min, 70) {

  .textbild__layout-1 {  

    &.textbild--c5 .textbild__element {
      @include cols(3.8);

      &:nth-of-type(4n+1) {
        clear: none;
      }

      &:nth-of-type(5n+1) {
        clear: both;
      }

    }


    &.textbild--c6 .textbild__element {
      @include cols(3.166666666);

      &:nth-of-type(5n+1) {
        clear: none;
      }

      &:nth-of-type(6n+1) {
        clear: both;
      }

    }
  }
}

