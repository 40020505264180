
body {
	font-family: $schrift1;
	color: #111;
	font-weight: $schrift1-regular;
}

h1 {
	font-family: $schrift2;
	font-size: 2.6rem;
	line-height: 1.3;
}

h2 {
	font-family: $schrift2;
	font-size: 1.4rem;
	line-height: 1.1;
}


p,
li,
h3 {
	font-family: $schrift1;
}

input,
textarea,
label {
	font-family: $schrift2;
}

p,
li,
h3,
input,
textarea,
label {
	font-size: 1.2rem;
	line-height: 1.5;
}


p,
li {
	-webkit-hyphens: auto;
	hyphens: auto;
}

h3 {
	font-weight: $schrift1-bold;
	padding-top: .6rem;
	padding-bottom: .6rem;
	border-top: .2rem solid #8e392d;
}

strong {
	font-weight: $schrift1-bold;
}

em {
	font-style: italic;
}


@include width(min, 40) {

	h1 {
		font-size: 3.2rem;
	}

	h2 {
		font-size: 1.9rem;
		color: $farbe1;

	}

	h3{
		padding-top: 1rem;
		padding-bottom: 1rem;
		border-top: .2rem solid #8e392d;
	}

	p,
	input,
	textarea,
	label,
	
	h3 {
		font-size: 1.4rem;
	}
	
	.nav__a {
  		text-decoration: none;
  		font-weight: $schrift1-bold;
  		color: #9b9b9b;
  		font-size: 1.1rem;
  	}


	
}
